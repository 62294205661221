import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Fragment } from 'react';
import { breakpoint } from '@emcasa/ui/lib/styles';
import { BREAKPOINTS } from '@emcasa/ui/lib/theme/measures';
import Text from '@emcasa/ui-dom/components/Text';
import View from '@emcasa/ui-dom/components/View';
import Col from '@emcasa/ui-dom/components/Col';
import Row from '@emcasa/ui-dom/components/Row';
import { Text as ExampleText } from './breakpoint.js';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "breakpoint"
    }}>{`breakpoint`}</h2>
    <p>{`Generate media query css interpolations.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`breakpoint.up`}</inlineCode>{` - Targets screens sizes larger than the selected breakpoint.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`breakpoint.down`}</inlineCode>{` - Targets screens sizes equal to or smaller than the selected breakpoint.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`breakpoint.only`}</inlineCode>{` - Targets only selected screen size`}</li>
    </ul>
    <p>{`Default breakpoints (min-width):`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`phone`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`tablet`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`desktop`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`0px`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`480px`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`780px`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import styled from 'styled-components'
import {themeGet} from 'styled-system'
import {breakpoint} from '@emcasa/ui/lib/styles'
import Text from '@emcasa/ui-dom/components/Text'

const ExampleText = styled(Text)\`
  @media only screen and \${breakpoint.only('phone')} { font-style: italic; }
  @media only screen and \${breakpoint.down('tablet')} { color: \${themeGet('colors.pink')}; }
  @media only screen and \${breakpoint.up('desktop')} { color: \${themeGet('colors.blue')}; }
\`
`}</code></pre>
    <Playground __position={0} __code={'<ExampleText>Hello world</ExampleText>'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      breakpoint,
      BREAKPOINTS,
      Text,
      View,
      Col,
      Row,
      ExampleText
    }} mdxType="Playground">
  <ExampleText mdxType="ExampleText">Hello world</ExampleText>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      